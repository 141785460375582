import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"

import { Link } from "gatsby"

const privacyPolicy = ({ pageContext, location }) => {
  return (
    <Layout>
      <InnerHeader
        pageTitle="Privacy Policy"
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container inner-y-padding">
        <h1>Rocky Brands Privacy Policy</h1>
        <p>Last Updated: August 10th, 2021</p>

        <h3>Our Commitment To Privacy</h3>
        <p>
          Your privacy is important to Rocky Brands. To protect the privacy of
          the visitors ("you") to the Rocky® websites ("Sites"), Rocky®
          ("/us/our") has developed this Privacy Policy ("this Policy") to
          explain our online information practices and the choices you can make
          about the way your information is collected and used. Please read
          below for more specific information on the type of information that we
          collect and how we use it.
        </p>

        <h3>Types Of Information We Collect</h3>

        <p>
          We will ask you when we need information that identifies you
          individually or allows us to contact you directly ("personal
          information").{" "}
        </p>

        <p>
          We may ask for personal information when you purchase a product, enter
          a contest, request a service, complete a survey, subscribe to a news,
          or engage in some other activity on our sites. In connection with
          these activities, we may ask you for contact information such as your
          name, telephone number, and email address.
        </p>

        <p>
          When you visit our sites, we collect standard information about your
          hardware and software such as your IP addresses, browser type,
          operating system, pages visited on our site, referring and exit pages,
          and the dates and times of the visits.{" "}
        </p>

        <p>
          This site visitation data is used to administer the site and provide
          general statistics regarding the use of Rocky Brands. Site visitation
          data is never linked to your personal information, and we do not use
          it to identify you individually without your permission.{" "}
        </p>

        <p>
          We will keep any and all information collected from you at this site
          strictly confidential and we will not sell, rent, trade, or otherwise
          share your personal information with third parties for their
          promotional use without your permission.{" "}
        </p>

        <h3>How We Use The Information We Collect</h3>

        <p>
          Information collected from any of our sites may be used for the
          purpose of sending promotional information, contest information,
          product specials, or services to you if have submitted your
          information for these purposes. We sometimes use this information to
          communicate with you, such as to notify you when you have won one of
          our contests or when we make changes to our subscriber agreements
          and/or any programs you may have signed up for.
        </p>

        <p>
          If we do send any promotional emails, we will provide you with an
          email address or Opt-Out link by which you may inform us that you do
          not wish to receive such materials in the future. You can opt-out of
          communications from Rocky Brands at any time (see "Control of Personal
          Information" below).
        </p>

        <p>
          We will not share any personal information without your permission
          with any unaffiliated third parties, unless required by law.{" "}
        </p>

        <p>
          We will use the personal information you give us to fulfill the
          services and provide the products you request from us. This may
          include sending information that you have requested, or contacting you
          about products and services that you have purchased.
        </p>

        <p>
          We sometimes use the non-personally identifiable information that we
          collect to improve the design and content of our site and to help us
          improve your overall Internet experience. For example, we may use this
          data to tailor your experience at our website, showing you content
          that we think you might be interested in, and displaying content
          according to your preferences. We also may use this information to
          analyze site usage.{" "}
        </p>

        <p>
          We sometimes contract with other companies to provide services such as
          packaging and mailing information, answering user questions, or
          processing credit card transactions. We will only provide these
          partners with the information they need to conduct their services,
          they will be prohibited from using the information we provide for any
          other purpose and we will require them to observe the intent of this
          Policy.
        </p>

        <p>
          We may disclose your personal information when we reasonably believe
          that it is required by a law or legal process and to protect and
          defend our rights and interests and the rights and interests of our
          affiliates, suppliers, or users. We also reserve the right to report
          to law enforcement agencies any activities that we in good faith
          believe to be unlawful. We may release certain personal information
          when we believe that such release is reasonably necessary to enforce
          or apply these terms and conditions or to protect the rights,
          property, and safety of others and ourselves.
        </p>

        <p>
          In the event we go through a business transition, such as a merger, an
          acquisition by another company, or a sale of all or a portion of our
          assets, users' personal information will, in most instances, be part
          of the assets transferred. Notifications of such a transition will be
          made using the mechanisms described in the "Changes to this Statement"
          section below.
        </p>

        <h3>Use Of Cookies And Other Electronic Tools</h3>

        <p>
          We and/or our partners (e.g., service providers, business partners,
          advertisers and advertising servers) may place, view, and/or use
          cookies, Web server logs, Web beacons, or other electronic tools to
          collect statistical and other information about your use of our sites
          and other websites.
        </p>

        <p>
          To pursue the objectives described in this Privacy Policy, information
          collected about your use of our sites or other websites may be
          aggregated, analyzed or combined with personal or other information
          about you from other online or offline sources. If you prefer that we
          not combine the information that has been collected, through cookies,
          about your use of our sites or other websites with personal or other
          information about you, then please delete and disable cookies for each
          browser, on each computer you use to surf the Web.
        </p>

        <p>
          Most browsers are set to accept cookies by default. If you prefer, you
          can usually set your browser to disable cookies, or to alert you when
          cookies are being sent. Likewise, most mobiledevices allow you to
          disable the ability for geo-location information to be collected from
          your mobile device. The help function on most browsers and mobile
          devices contains instructions on how to set your browser to notify you
          before accepting cookies, disable cookies entirely, or disable the
          collection of geo-location data.
        </p>

        <p>
          However, if you disable cookies or refuse to accept a request to place
          a cookie, it is possible that some parts of our sites, such as the
          surveys, contests, and promotions, and certain areas of our sites for
          which you need to log in, will not function properly, and the
          advertising you receive when you visit our sites may not be
          advertisements tailored to your interests.{" "}
        </p>

        <h3>Third Party Cookies</h3>

        <p>
          In the course of serving advertisements to you in emails, third-party
          advertisers or ad servers may place or recognize a unique cookie on
          your browser. The use of cookies by such third party advertisers or ad
          servers is not subject to this Policy, but is subject to the privacy
          policies of those third parties.
        </p>

        <h3>Links From Email</h3>

        <p>
          We provide links to our advertiser and partner websites and other
          websites. If you link to any of these third party websites, this
          Policy will not apply to your use of, and activities on, those sites.
          We encourage you to read the legal notices posted on these sites,
          including their privacy policies. We have no responsibility or
          liability for your visits to, and the data collection and use
          practices of, these sites.
        </p>

        <h3>Note Concerning Minors</h3>

        <p>
          The sites and emails, and the content available in connection
          therewith, are neither intended for, nor directed to, children under
          the age of 16. Additionally, you must be at least 16 years old to have
          our permission to use our sites. If we learn that a person is under
          the age of 16, we will promptly terminate that individual's account.
        </p>

        <h3>Data Storage And Retention</h3>

        <p>
          We store your personal information on our servers and the servers of
          third party service providers, all located in the United States. We
          retain your personal information for the duration of the business
          relationship and for a period thereafter to allow you to recover your
          account information, to analyze your data for our business operations,
          and for archiving purposes. For more information on where or how long
          your data is stored, please contact us at{" "}
          <Link to="mailto: dataprivacy@rockybrands.com">
            dataprivacy@rockybrands.com
          </Link>
          .{" "}
        </p>

        <h3>Transferring Personal Information From The EU To The US</h3>

        <p>
          Rocky® is headquartered in the United States. Information you provide
          to us or we collect from you will be processed in the United States.
          The United States is not currently considered by the EU to provide an
          adequate level of data protection. We therefore rely on exceptions
          listed in the GDPR. In particular, we collect and transfer your
          personal information to the United States only: with your consent; to
          perform Services under the contracts we have in place with you; or to
          fulfill a compelling legitimate interest of Rocky® that does not
          outweigh your rights or freedoms. We strive to apply suitable
          safeguards to protect the privacy and security of your personal
          information and to use it only as described in this Policy. We also
          enter into data processing agreements and standard contractual clauses
          with our vendors, whenever feasible and appropriate. For more
          information regarding the GDPR and data transfers, please go to{" "}
          <Link to="https://ec.europa.eu/commission/priorities/justice-and-fundamental-rights/data-protection/2018-reform-eu-data-protection-rules_en">
            https://ec.europa.eu/commission/priorities/justice-and-fundamental-rights/data-protection/2018-reform-eu-data-protection-rules_en
          </Link>
          .{" "}
        </p>

        <h3>Our Commitment To Data Security</h3>

        <p>
          Your privacy is important to us and because of this all credit card
          transactions made through any Rocky Brands website are protected and
          guaranteed secure through VeriSigns SSL encryption. Only those Rocky
          Brands employees who need access to your information in order to
          perform their duties are allowed access. Any employee who violates our
          privacy and/or security policies may be subject to disciplinary
          action, including possible termination and civil and/or criminal
          prosecution.
        </p>

        <h3>Changing Your Information</h3>

        <p>
          The only potentially personally identifying information that we keep
          on file with respect to communications through this Site is your
          Rocky® Mail and Rocky® Newsletter sign-up information. If you would
          like to change your email address, we recommend that you first opt out
          of receiving promotional emails by following the opt out procedures
          included with the promotional email when received. You may then enter
          the alternative email address to which you desire to receive
          promotional emails through either the Rocky® Mail or Rocky® Newsletter
          activation functions of the Site.
        </p>

        <h3>Your Rights With Respect To Your Personal Information</h3>

        <p>
          Depending on the location from where you access to the sites, you may
          have the right to access, correct, delete, or restrict use of certain
          personal information covered by this Policy and exercise all other
          rights set forth in Articles 15-22 of the General Data Protection
          Regulation ("GDPR"). You may also have the right to request that we
          refrain from processing your personal information. Please bear in mind
          that if you exercise such rights this may affect our ability to
          provide our products and services. For inquiries about your personal
          information, please contact us. While we will make reasonable efforts
          to accommodate your request, we also reserve the right to impose
          certain restrictions and requirements on such requests, if allowed or
          required by applicable laws.
        </p>

        <p>Please note that it may take some time to process your request.</p>

        <h3>Changes To Our Privacy Policy</h3>

        <p>
          We may from time to time update this Policy. When we make these
          updates, we will change the "last updated" date listed at the top of
          the statement. If we make a material change to this statement that
          affects our use of your personal information, we will update users by
          placing a more prominent notice on our home page.
        </p>

        <h3>Other Websites</h3>

        <p>
          The Rocky® sites or our partner sites may contain links to other
          websites outside of Rocky Brands Corporate. Other sites may also
          reference or link to our sites. We are not responsible for the privacy
          practices or the content of such other sites.
        </p>

        <h3>How To Contact Us</h3>

        <p>
          For any other information or questions regarding the Site or the
          information you have submitted to us through the Site, please contact
          us at (740) 753-1951 or send us an email to{" "}
          <Link to="mailto: dataprivacy@rockybrands.com">
            dataprivacy@rockybrands.com
          </Link>
          .{" "}
        </p>

        <p>
          Please also visit our{" "}
          <Link to="/terms-and-conditions">Site Terms and Conditions</Link>{" "}
          which explains the terms governing you use of our website.
        </p>

        <p>Copyright © 2021 Rocky Brands™, Inc. All Rights Reserved.</p>
      </div>
    </Layout>
  )
}

export default privacyPolicy
